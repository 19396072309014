import React from "react"
import SuperTokens from "supertokens-auth-react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Home from "./Routes/Home"
import PatientWrapper from "./Routes/Patient/PatientWrapper"
import ErrorPage from "./Routes/ErrorPage"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import Session from "supertokens-auth-react/recipe/session"
import Passwordless from "supertokens-auth-react/recipe/passwordless"
import Multitenancy from "supertokens-auth-react/recipe/multitenancy"
import HealthcareWrapper from "./Routes/Healthcare/Pages/HealthcareWrapper"
import EmailPassword from "supertokens-auth-react/recipe/emailpassword"
import UnauthorizedPage from "./Routes/UnauthorizedPage"
import EmailVerification from "supertokens-auth-react/recipe/emailverification"
import { Toaster } from "@/components/Toaster"
import VerifyEmailPage from "./Routes/VerifyEmailPage"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import ThirdParty from "supertokens-auth-react/recipe/thirdparty"

SuperTokens.init({
  appInfo: {
    appName: import.meta.env.VITE_SUPERTOKENS_APP_NAME,
    apiDomain: import.meta.env.VITE_SUPERTOKENS_API_DOMAIN,
    websiteDomain: import.meta.env.VITE_SUPERTOKENS_WEBSITE_DOMAIN,
    apiBasePath: import.meta.env.VITE_SUPERTOKENS_API_BASE_PATH,
    websiteBasePath: import.meta.env.VITE_SUPERTOKENS_WEBSITE_BASE_PATH,
  },
  usesDynamicLoginMethods: true,
  recipeList: [
    Session.init(),
    Passwordless.init({
      contactMethod: "PHONE",
    }),
    ThirdParty.init(),
    EmailPassword.init({
      signInAndUpFeature: {
        signUpForm: {
          formFields: [
            {
              id: "firstName",
              label: "First Name",
              placeholder: "First name",
            },
            {
              id: "lastName",
              label: "Last Name",
            },
            {
              id: "title",
              label: "Title",
              placeholder: "Enter title e.g. CEO, CTO",
            },
            {
              id: "providerName",
              label: "Provider Name",
              placeholder: "Enter hospital e.g. Kijabe Hospital",
              optional: true,
            },
            {
              id: "providerAddress",
              label: "Care Provider Address",
              placeholder: "Enter your provider address",
              optional: true,
            },
            {
              id: "inviteId",
              label: "Invite Id",
              placeholder: "Enter invite id",
              optional: true,
            },
          ],
        },
      },
    }),
    EmailVerification.init({
      mode: "REQUIRED", // or "OPTIONAL"
      disableDefaultUI: true,
    }),
    Multitenancy.init({
      override: {
        functions: (oI) => {
          return {
            ...oI,
            getTenantId: () => {
              const tid = localStorage.getItem("tenantId")

              return tid === null ? "patients" : JSON.parse(tid)
            },
          }
        },
      },
    }),
  ],
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
    },
  },
})

/* Your App */
export default class App extends React.Component {
  router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/verify-email",
      element: <VerifyEmailPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/patients/*",
      element: <PatientWrapper />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/healthcare/*",
      element: <HealthcareWrapper />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/unauthorized",
      element: <UnauthorizedPage />,
      errorElement: <ErrorPage />,
    },
  ])

  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <Toaster />
        <RouterProvider router={this.router} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    )
  }
}
