import { useForm } from "react-hook-form"
import HealthcareAuthTitle from "../components/auth/HealthcareAuthTitle"
import FormGroupInput from "@/components/form/FormGroupInput"
import { useMutation } from "@tanstack/react-query"
import { submitNewPassword } from "supertokens-web-js/recipe/emailpassword"
import { Link } from "react-router-dom"
import { Button } from "@/components/Button"

type Inputs = {
  healthcarePassword: string
  healthcareConfirmPassword: string
}

export default function ResetPassword() {
  const mutation = useMutation({
    mutationFn: async (data: Inputs) => {
      const response = await submitNewPassword({
        formFields: [
          {
            id: "password",
            value: data.healthcarePassword,
          },
        ],
      })

      if (response.status === "FIELD_ERROR") {
        response.formFields.forEach((formField) => {
          if (formField.id === "password") {
            // New password did not meet password criteria on the backend.
            throw new Error(formField.error)
          }
        })
      } else if (response.status === "RESET_PASSWORD_INVALID_TOKEN_ERROR") {
        // the password reset token in the URL is invalid, expired, or already consumed
        throw new Error("Password reset failed. Please try again")
      }
      //success
      return true
    },
  })
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>()

  if (mutation.isError) {
    return <ErrorView />
  }

  if (mutation.isSuccess) {
    return <SuccessView />
  }

  return (
    <form
      className="flex flex-col gap-5 text-center max-w-[350px] mx-auto"
      onSubmit={handleSubmit(async (data) => await mutation.mutate(data))}
    >
      <HealthcareAuthTitle>
        Enter a new password for your account
      </HealthcareAuthTitle>

      <p>
        Password must be at least 6 characters long and contain a mix of letters
        and numbers
      </p>

      <FormGroupInput
        id="healthcarePassword"
        label="Password"
        type="password"
        placeholder="Enter your password"
        register={register("healthcarePassword", {
          required: {
            value: true,
            message: "Please enter your password",
          },
          minLength: {
            value: 6,
            message: "Password must be at least 6 characters",
          },
        })}
        error={errors.healthcarePassword?.message}
      />

      <FormGroupInput
        id="healthcareConfirmPassword"
        label="Confirm Password"
        type="password"
        placeholder="Confirm your password"
        register={register("healthcareConfirmPassword", {
          required: {
            value: true,
            message: "Please confirm your password",
          },
          validate: (value) => {
            if (value !== watch("healthcarePassword")) {
              return "Passwords do not match"
            }
          },
        })}
        error={errors.healthcareConfirmPassword?.message}
      />

      <Button isLoading={mutation.isPending} disabled={mutation.isPending}>
        Reset Password
      </Button>
    </form>
  )
}

function SuccessView() {
  return (
    <div className="flex flex-col gap-5 text-center ">
      <HealthcareAuthTitle>Password Reset Successful</HealthcareAuthTitle>
      <p>Your password has been reset successfully.</p>
      <p>
        <Link to="/healthcare/auth">Click here</Link> to sign in with your new
        password.
      </p>
    </div>
  )
}

function ErrorView() {
  return (
    <div className="flex flex-col gap-5 text-center ">
      <HealthcareAuthTitle>Failed to Reset Password</HealthcareAuthTitle>
      <p>Something went wrong. Please try again.</p>
      <p>
        <Link to="/healthcare/auth/forgot-password">Click here</Link> to send a
        new reset password link.
      </p>
    </div>
  )
}
