import { Button } from "@/components/Button"
import FormGroupInput from "@/components/form/FormGroupInput"
import { useMutation } from "@tanstack/react-query"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { Link, NavigateFunction } from "react-router-dom"
import { signUp } from "supertokens-auth-react/recipe/emailpassword"
import { sendVerificationEmail } from "supertokens-auth-react/recipe/emailverification"
import HealthcareAuthTitle from "./HealthcareAuthTitle"
import axios from "axios"
import { toast } from "@/hooks/useToast"
import CheckEmail from "./CheckEmail"

type Inputs = {
  healthcareEmail: string
  healthcarePhoneNumber: string
  healthcareFirstName: string
  healthcareLastName: string
  healthcarePassword: string
  healthcareConfirmPassword: string
  healthcareTitle: string
  healthcareProviderName: string
  healthcareProviderAddress: string
}

export default function BasicDetailsForm({
  navigate,
}: {
  navigate: NavigateFunction
}) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>()

  const [showCheckEmail, setShowCheckEmail] = useState(false)

  const mutation = useMutation({
    mutationFn: async (data: Inputs) => {
      //Supertokens does not provide pre-signup hooks to perform validation so we must do validation check here
      await axios.get(
        import.meta.env.VITE_API_BASE_URL +
          `/healthcare/verify-facility-name-uniqueness/${data.healthcareProviderName}`
      )

      const response = await signUp({
        formFields: [
          {
            id: "email",
            value: data.healthcareEmail,
          },
          {
            id: "phoneNumber",
            value: data.healthcarePhoneNumber,
          },
          {
            id: "password",
            value: data.healthcarePassword,
          },
          {
            id: "firstName",
            value: data.healthcareFirstName,
          },
          {
            id: "lastName",
            value: data.healthcareLastName,
          },
          {
            id: "title",
            value: data.healthcareTitle,
          },
          {
            id: "providerName",
            value: data.healthcareProviderName,
          },
          {
            id: "providerAddress",
            value: data.healthcareProviderAddress,
          },
        ],
      })

      if (response.status === "FIELD_ERROR") {
        // one of the input formFields failed validation
        throw new Error(response.formFields[0].error)
      } else if (response.status === "SIGN_UP_NOT_ALLOWED") {
        // the reason string is a user friendly message
        // about what went wrong. It can also contain a support code which users
        // can tell you so you know why their sign up was not allowed.
        throw new Error(response.reason)
      }

      //send verification email
      const verificationResponse = await sendVerificationEmail()
      if (verificationResponse.status === "EMAIL_ALREADY_VERIFIED_ERROR") {
        // This can happen if the info about email verification in the session was outdated.
        // Redirect the user to the home page
        navigate("/healthcare")
      } else {
        // email was sent successfully.
        setShowCheckEmail(true)
      }
    },
    onError: (error: any) => {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        variant: "destructive",
      })
    },
  })

  if (showCheckEmail) {
    return <CheckEmail />
  }

  return (
    <form
      className=""
      onSubmit={handleSubmit(async (data) => await mutation.mutate(data))}
    >
      <div className="flex flex-col gap-5 text-center items-center">
        <HealthcareAuthTitle>Set up your account</HealthcareAuthTitle>

        <p className="max-w-[55ch] text-sm">
          We value your time, which is why we developed the Care Provider
          Portal. It offers convenient access to managing patients efficiently
        </p>

        <p className="font-medium">
          Already have an account? <Link to="/healthcare/auth">Log in</Link>
        </p>
      </div>

      <div className="grid sm:grid-cols-2 max-w-[500px] gap-7 mx-auto mt-10">
        <FormGroupInput
          id="healthcareEmail"
          label="Email"
          type="email"
          placeholder="Enter your email address"
          register={register("healthcareEmail", {
            required: {
              value: true,
              message: "Please enter your email address",
            },
            pattern: {
              value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: "Please enter a valid email address",
            },
          })}
          error={errors.healthcareEmail?.message}
        />
        <FormGroupInput
          id="healthcarePhoneNumber"
          label="Phone Number"
          type="tel"
          placeholder="Enter your phone number"
          register={register("healthcarePhoneNumber", {
            required: {
              value: true,
              message: "Please enter your phone number",
            },
          })}
          error={errors.healthcarePhoneNumber?.message}
        />
        <FormGroupInput
          id="healthcareFirstName"
          label="First Name"
          type="text"
          placeholder="Enter your first name"
          register={register("healthcareFirstName", {
            required: {
              value: true,
              message: "Please enter your first name",
            },
          })}
          error={errors.healthcareFirstName?.message}
        />

        <FormGroupInput
          id="healthcareLastName"
          label="Last Name"
          type="text"
          placeholder="Enter your last name"
          register={register("healthcareLastName", {
            required: {
              value: true,
              message: "Please enter your last name",
            },
          })}
          error={errors.healthcareLastName?.message}
        />

        <FormGroupInput
          id="healthcareTitle"
          label="Title"
          type="text"
          placeholder="Enter title e.g. CEO, CTO"
          register={register("healthcareTitle", {
            required: {
              value: true,
              message: "Please enter your title",
            },
          })}
          error={errors.healthcareTitle?.message}
        />

        <FormGroupInput
          id="healthcareProviderName"
          label="Provider Name"
          type="text"
          placeholder="Enter hospital e.g. Kijabe Hospital"
          register={register("healthcareProviderName", {
            required: {
              value: true,
              message: "Please enter your provider name",
            },
          })}
          error={errors.healthcareProviderName?.message}
        />

        <FormGroupInput
          className="sm:col-span-2"
          id="healthcareProviderAddress"
          label="Care Provider Address"
          type="text"
          placeholder="Enter your provider address"
          register={register("healthcareProviderAddress", {
            required: {
              value: true,
              message: "Please enter your provider address",
            },
          })}
          error={errors.healthcareProviderAddress?.message}
        />

        <FormGroupInput
          id="healthcarePassword"
          label="Password"
          type="password"
          placeholder="Enter your password"
          register={register("healthcarePassword", {
            required: {
              value: true,
              message: "Please enter your password",
            },
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters",
            },
          })}
          error={errors.healthcarePassword?.message}
        />

        <FormGroupInput
          id="healthcareConfirmPassword"
          label="Confirm Password"
          type="password"
          placeholder="Confirm your password"
          register={register("healthcareConfirmPassword", {
            required: {
              value: true,
              message: "Please confirm your password",
            },
            validate: (value) => {
              if (value !== watch("healthcarePassword")) {
                return "Passwords do not match"
              }
            },
          })}
          error={errors.healthcareConfirmPassword?.message}
        />

        <Button
          className="sm:col-span-2"
          isLoading={mutation.isPending}
          disabled={mutation.isPending}
        >
          Submit
        </Button>
      </div>
    </form>
  )
}
