import { useForm } from "react-hook-form"
import HealthcarePageContainer from "../HealthcarePageContainer"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import LoadingPage from "@/Routes/LoadingPage"
import ErrorBlock from "@/components/ErrorBlock"
import Subtitle from "../typography/SubTitle"
import Description from "../typography/Description"
import { HealthcareWorkerRole } from "../../stores/healthcareUserStore"
import ForbiddenBlock from "@/components/ForbiddenBlock"
import FormGroupInput from "@/components/form/FormGroupInput"
type Inputs = {
  facilityName: string
  facilityAddress: string
}

const queryKey = "healthcareProviderProfileDetails"
const allowedRoles = [HealthcareWorkerRole.ADMIN, HealthcareWorkerRole.OWNER]
export default function HealthcareProviderSettings() {
  const {
    register,
    formState: { errors },
  } = useForm<Inputs>()

  const query = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      const response = await axios.get(
        import.meta.env.VITE_API_BASE_URL +
          "/healthcare/healthcare-profile-details"
      )

      return response.data
    },
  })

  if (query.isLoading) return <LoadingPage />
  if (query.isError) return <ErrorBlock />

  const {
    firstName,
    lastName,
    role,
    title,
    facilityName,
    facilityAddress,
    hospitalRegistrationNumber,
  } = query.data

  if (!allowedRoles.includes(role)) {
    return <ForbiddenBlock />
  }

  return (
    <HealthcarePageContainer
      title="Care Provider Profile"
      description="Manage your care provider profile"
    >
      <div className="flex flex-col gap-10 border rounded-xl max-w-[1000px]">
        <div className="w-full h-32 bg-primary/30 rounded-xl relative">
          <div
            className="rounded-full w-24 h-24  grid place-content-center text-5xl border-[6px] border-white bg-primary text-white absolute left-1/2 -bottom-8 -ml-12"
            aria-hidden="true"
          >
            {firstName[0]}
          </div>
        </div>

        <div className="text-center flex flex-col gap-2 p-5">
          <h2 className="text-2xl font-medium">
            {firstName} {lastName}
          </h2>
          <h3 className="font-medium text-gray-500">
            {title} - {facilityName}
          </h3>

          <form className="grid md:grid-cols-2 gap-7 mt-5">
            <FormGroupInput
              id="facilityName"
              label="Facility Name"
              type="text"
              placeholder="Enter your facility name"
              readonly={true}
              register={register("facilityName", {
                value: facilityName,
              })}
              error={errors.facilityName?.message}
            />

            <FormGroupInput
              id="facilityAddress"
              label="Facility Address"
              type="text"
              placeholder="Enter your facility address"
              readonly={true}
              register={register("facilityAddress", {
                value: facilityAddress,
              })}
              error={errors.facilityAddress?.message}
            />
          </form>
          <div className="flex flex-col gap-2 mt-10 text-left">
            <Subtitle>Hospital Verification Documents</Subtitle>
            <Description>
              Your legal identity verification, once approved, you will not be
              able to edit this
            </Description>

            <div className="grid md:grid-cols-2 mt-5">
              <div className="flex flex-col gap-2">
                <label
                  className="text-sm font-medium text-gray-500"
                  htmlFor="healthcareRegistrationNumber"
                >
                  Healthcare Registration Number
                </label>

                <input
                  type="text"
                  className="w-full"
                  id="healthcareRegistrationNumber"
                  value={hospitalRegistrationNumber}
                  readOnly
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  className="text-sm font-medium text-gray-500"
                  htmlFor="ownerIdNumber"
                >
                  Hospital Documents
                </label>
                <input
                  type="text"
                  className="w-full"
                  id="ownerIdNumber"
                  value="Submitted"
                  readOnly
                />
              </div>
            </div>

            <div className="flex flex-col gap-2 mt-10">
              <Subtitle>Commercial Agreement</Subtitle>
              <Description>
                Signed commercial agreement between Kijabe hospital & Jireh
              </Description>

              <div className="shadow-sm flex flex-col gap-7  w-full  h-[65vh] mx-auto border overflow-y-scroll text-left mt-3">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nemo
                suscipit accusantium doloremque, exercitationem vero nihil
                delectus iusto officiis nesciunt ullam beatae ea ducimus fuga in
                pariatur, sit aperiam atque, praesentium distinctio quia
                voluptates dignissimos nobis. Ipsam nobis recusandae ducimus
                repellat corporis, cumque cum nam quia saepe architecto
                excepturi quasi dolore, sunt eius odio magni! Id ratione
                accusantium, pariatur ea hic repellendus expedita provident
                ipsum dolores fuga molestiae cumque, nostrum qui. Illum
                blanditiis fugiat doloremque accusantium. Ab fuga eum iure quas,
                eos autem labore cum cupiditate necessitatibus natus enim magnam
                sequi facere at odio earum sint laudantium pariatur! Nihil
                temporibus ea sint molestias numquam inventore ut debitis
                assumenda quisquam doloremque vitae incidunt, laborum, ipsa
                dolores praesentium ratione, odio ab obcaecati molestiae? Totam
                perferendis, temporibus vel quia, voluptas aliquid saepe
                corrupti laudantium quod magni voluptate dolorem doloribus ullam
                voluptatibus officiis omnis facilis? Praesentium, veniam
                voluptatum in ad, nostrum incidunt hic cumque voluptatem nam,
                iure et consequuntur possimus rem tempore qui rerum laboriosam
                ipsa exercitationem quis neque libero. Deserunt nesciunt ipsa
                sed voluptatibus itaque cupiditate, facere harum corrupti beatae
              </div>
            </div>
          </div>
        </div>
      </div>
    </HealthcarePageContainer>
  )
}
