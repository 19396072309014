import { Button } from "@/components/Button"
import FormGroup from "@/components/form/FormGroupInput"
import PatientAuthWrapper from "@/Routes/Patient/components/PatientAuthWrapper"
import { Controller, useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import { useState } from "react"
import VerifyOTPForm from "../components/VerifyOTPForm"
import PatientAuthHeadline from "../components/PatientAuthHeadline"
import { usePatientAuthStore } from "../stores/patientAuthStore"
import { useMutation, useQuery } from "@tanstack/react-query"
import { createCode } from "supertokens-auth-react/recipe/passwordless"
import { CountryCode, parsePhoneNumber } from "libphonenumber-js"
import { validatePhoneNumber } from "@/utilities/validators"
import FormGroupSelect from "@/components/form/FormGroupSelect"
import { useToast } from "@/hooks/useToast"
import { Checkbox } from "@/components/Checkbox"
import axios from "axios"
import ErrorBlock from "@/components/ErrorBlock"
import LoadingPage from "@/Routes/LoadingPage"

export type Inputs = {
  phoneNumber: string
  countryCode: CountryCode
  whatsappPhoneNumber: string
  whatsappCountryCode: CountryCode
  patientFirstName: string
  patientLastName: string
}

export default function PatientSignUp() {
  const [showOtpPage, setShowOtpPage] = useState(false)

  return (
    <PatientAuthWrapper>
      {!showOtpPage ? (
        <SignUpForm setShowOtpPage={setShowOtpPage} />
      ) : (
        <VerifyOTPForm setShowOtpPage={setShowOtpPage} />
      )}
    </PatientAuthWrapper>
  )
}

export const countryCodesQueryKey = "getCountryCodes"
function SignUpForm({ setShowOtpPage }: { setShowOtpPage: Function }) {
  const query = useQuery({
    queryKey: [countryCodesQueryKey],
    queryFn: async () => {
      const response = await axios.get(
        `${import.meta.env.VITE_SUPERTOKENS_API_DOMAIN}/country-codes`
      )

      return response.data
    },
  })

  const { toast } = useToast()
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<Inputs>()

  const setSignUpDetails = usePatientAuthStore(
    (state: any) => state.setSignUpDetails
  )

  const [numberIsWhatsapp, setNumberIsWhatsapp] = useState(false)

  const mutation = useMutation({
    mutationFn: async (data: Inputs) => {
      const phoneNumber = parsePhoneNumber(data.phoneNumber, data.countryCode)

      const response = await createCode({
        phoneNumber: phoneNumber.number,
      })

      if (response.status === "SIGN_IN_UP_NOT_ALLOWED") {
        // the reason string is a user friendly message
        // about what went wrong. It can also contain a support code which users
        // can tell you so you know why their sign in / up was not allowed.
        throw new Error(response.reason)
      }

      console.log(response)

      setSignUpDetails(
        data.phoneNumber,
        data.countryCode,
        data.patientFirstName,
        data.patientLastName,
        numberIsWhatsapp ? data.phoneNumber : data.whatsappPhoneNumber,
        numberIsWhatsapp ? data.countryCode : data.whatsappCountryCode
      )
      setShowOtpPage(true)

      return true
    },
    onError: (error: any) => {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        variant: "destructive",
      })
    },
  })

  if (query.isLoading) {
    return <LoadingPage />
  }

  if (query.isError) {
    return <ErrorBlock />
  }

  return (
    <form
      className="flex flex-col gap-7"
      onSubmit={handleSubmit(async (data) => {
        await mutation.mutateAsync(data)
      })}
    >
      <PatientAuthHeadline text="Please type in your phone number to sign up" />

      <FormGroup
        id="patientFirstName"
        label="First Name"
        type="text"
        placeholder="Enter your first name"
        register={register("patientFirstName", {
          required: {
            value: true,
            message: "Please enter your first name",
          },
        })}
        error={errors.patientFirstName?.message}
      />
      <FormGroup
        id="patientLastName"
        label="Last Name"
        type="text"
        placeholder="Enter your last name"
        register={register("patientLastName", {
          required: {
            value: true,
            message: "Please enter your last name",
          },
        })}
        error={errors.patientLastName?.message}
      />

      <Controller
        name="countryCode"
        control={control}
        rules={{
          required: {
            value: true,
            message: "Please select your country",
          },
        }}
        render={({ field }) => (
          <FormGroupSelect
            id="countryCode"
            label="Country Code"
            placeholder="Select Country Code"
            field={field}
            error={errors.countryCode?.message}
            options={[
              {
                name: "Kenya",
                value: "KE",
              },
            ]}
          />
        )}
      />

      <FormGroup
        id="phoneNumber"
        label="Phone Number"
        type="phone"
        placeholder="0712345678"
        register={register("phoneNumber", {
          required: {
            value: true,
            message: "Please enter your phone number",
          },
          validate: (value) => {
            if (
              !validatePhoneNumber({
                countryCode: watch("countryCode"),
                phoneNumber: value,
              })
            ) {
              return "Please enter a valid phone number"
            }

            return true
          },
        })}
        error={errors.phoneNumber?.message}
      />

      {!numberIsWhatsapp && (
        <div className={`flex flex-col gap-5`}>
          <Controller
            name="whatsappCountryCode"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Please select your country",
              },
            }}
            render={({ field }) => (
              <FormGroupSelect
                id="whatsappCountryCode"
                label="WhatsApp Country Code"
                placeholder="Select Country Code"
                field={field}
                error={errors.whatsappCountryCode?.message}
                options={query.data.countryOptions}
              />
            )}
          />
          <FormGroup
            id="whatsappPhoneNumber"
            label="Whatsapp Number"
            type="text"
            placeholder="Enter your Whatsapp number"
            register={register("whatsappPhoneNumber", {
              validate: (value) => {
                if (
                  !validatePhoneNumber({
                    countryCode: watch("whatsappCountryCode"),
                    phoneNumber: value,
                  })
                ) {
                  return "Please enter a valid phone number"
                }

                return true
              },
            })}
            error={errors.whatsappPhoneNumber?.message}
          />
        </div>
      )}

      <div className="flex space-x-2">
        <Checkbox
          id="number is same as above"
          checked={numberIsWhatsapp}
          onCheckedChange={() => setNumberIsWhatsapp(!numberIsWhatsapp)}
          className="mt-1"
        />
        <label
          htmlFor="terms"
          className="text-sm text-gray-600 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          Tick the box if your Whatsapp number is the same as the above. Get
          important updates about your membership and treatment, so you're
          always in the loop!
        </label>
      </div>

      <Button
        className="w-full"
        size="lg"
        role="link"
        type="submit"
        disabled={mutation.isPending}
        isLoading={mutation.isPending}
      >
        Send OTP
      </Button>

      <Link to="/patients/auth/login" className="font-bold  mx-auto">
        Log In
      </Link>

      <p className="text-center">
        By continuing you agree to our{" "}
        <Link to="/privacy-policy">Privacy Policy</Link> and
        <Link to="/terms-of-service"> Terms of Service</Link>
      </p>
    </form>
  )
}
