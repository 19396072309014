import { useMutation, useQuery } from "@tanstack/react-query"
import PatientAuthHeadline from "../components/PatientAuthHeadline"
import PatientAuthWrapper from "../components/PatientAuthWrapper"
import axios from "axios"
import LoadingPage from "@/Routes/LoadingPage"
import ErrorBlock from "@/components/ErrorBlock"
import { formatDate } from "@/utilities/dateUtilities"
import { Controller, useForm } from "react-hook-form"
import { useToast } from "@/hooks/useToast"
import { useNavigate } from "react-router-dom"
import FormGroupInput from "@/components/form/FormGroupInput"
import FormGroupSelect from "@/components/form/FormGroupSelect"
import { Button } from "@/components/Button"
import { PatientIdVerificationStatus } from "../enums/PatientIdVerificationStatus"

export const employmentDetailsQueryKey = "patientEmploymentDetails"
export default function PatientEmploymentDetails() {
  return (
    <PatientAuthWrapper>
      <div>
        <PatientAuthHeadline
          text="Provide Membership
Information"
        />
        <p>Increase your approval success by adding your employment details.</p>
      </div>
      <IdVerificationDetails />
      <EmploymentDetailsForm />
    </PatientAuthWrapper>
  )
}

type EmploymentType = "FULL_TIME" | "PART_TIME" | "CONTRACT" | "OTHER"
type Inputs = {
  email: string
  homeAddress: string
  occupation: string
  nameOfEmployer: string
  employmentType: EmploymentType
  industry: string
}

function EmploymentDetailsForm() {
  const { toast } = useToast()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>()

  const mutation = useMutation({
    mutationFn: async (data: Inputs) => {
      const response = await axios.post(
        `${
          import.meta.env.VITE_SUPERTOKENS_API_DOMAIN
        }/patients/employment-details`,
        data
      )

      return response.data
    },
    onSuccess: (result) => {
      toast({
        title: "Success",
        description: result.message,
      })
      navigate("/patients/financial-statements")
    },
    onError: (error: any) => {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        variant: "destructive",
      })
    },
  })

  return (
    <form
      className="flex flex-col gap-5"
      onSubmit={handleSubmit(async (data) => {
        await mutation.mutateAsync(data)
      })}
    >
      <FormGroupInput
        id="email"
        label="Email"
        type="email"
        placeholder="Enter your email address"
        register={register("email", {
          required: {
            value: true,
            message: "Please enter your email address",
          },
          pattern: {
            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
            message: "Please enter a valid email address",
          },
        })}
        error={errors.email?.message}
      />

      <FormGroupInput
        id="homeAddress"
        label="Home Address"
        type="text"
        placeholder="Enter your home address"
        register={register("homeAddress", {
          required: {
            value: true,
            message: "Please enter your home address",
          },
        })}
        error={errors.homeAddress?.message}
      />

      <FormGroupInput
        id="occupation"
        label="Occupation"
        type="text"
        placeholder="Enter your occupation"
        register={register("occupation", {
          required: {
            value: true,
            message: "Please enter your occupation",
          },
        })}
        error={errors.occupation?.message}
      />

      <FormGroupInput
        id="nameOfEmployer"
        label="Name of Employer"
        type="text"
        placeholder="Enter your name of employer"
        register={register("nameOfEmployer", {
          required: {
            value: true,
            message: "Please enter your name of employer",
          },
        })}
        error={errors.nameOfEmployer?.message}
      />

      <Controller
        name="employmentType"
        control={control}
        rules={{
          required: {
            value: true,
            message: "Please select your employment type",
          },
        }}
        render={({ field }) => (
          <FormGroupSelect
            id="employmentType"
            label="Employment Type"
            placeholder="Select Employment Type"
            field={field}
            error={errors.employmentType?.message}
            options={[
              {
                name: "Full Time",
                value: "FULL_TIME",
              },
              {
                name: "Part Time",
                value: "PART_TIME",
              },
              {
                name: "Contract",
                value: "CONTRACT",
              },
              {
                name: "Other",
                value: "OTHER",
              },
            ]}
          />
        )}
      />

      <FormGroupInput
        id="industry"
        label="Industry"
        type="text"
        placeholder="Enter your industry"
        register={register("industry", {
          required: {
            value: true,
            message: "Please enter your industry",
          },
        })}
        error={errors.industry?.message}
      />

      <Button
        className="w-full"
        size="lg"
        role="link"
        type="submit"
        disabled={mutation.isPending}
        isLoading={mutation.isPending}
      >
        Submit
      </Button>
    </form>
  )
}

function IdVerificationDetails() {
  const query = useQuery({
    queryKey: [employmentDetailsQueryKey],
    queryFn: async () => {
      const response = await axios.get(
        `${
          import.meta.env.VITE_SUPERTOKENS_API_DOMAIN
        }/patients/id-verification-details`
      )

      return response.data
    },
  })

  if (query.isLoading) {
    return <LoadingPage />
  }

  if (query.isError) {
    const { error } = query as any
    return <ErrorBlock message={error.response?.data.message} />
  }

  const {
    firstName,
    lastName,
    otherName,
    photo,
    idType,
    idNumber,
    dateOfBirth,
    idVerificationStatus,
    gender,
  } = query.data

  if (idVerificationStatus !== PatientIdVerificationStatus.VERIFIED) {
    return null
  }

  return (
    <div className="grid grid-cols-3 gap-3 ">
      <img
        src={"data:image/jpeg;base64," + photo}
        alt="Photo"
        className="h-[100px] aspect-square mx-auto rounded-full object-cover border-2 border-primary my-auto"
      />

      <div className="col-span-2 flex flex-col justify-center">
        <h1 className=" font-medium">
          {firstName} {otherName} {lastName}
        </h1>
        <h2 className="text-gray-500 font-medium">
          {idType} {idNumber}
        </h2>

        <h3>DOB: {formatDate(dateOfBirth)}</h3>
        <h3>Gender: {gender}</h3>
      </div>

      {/* <h3 className="flex flex-col col-span-3">
        <div className="font-medium">ID Address</div>
        {address}
      </h3> */}
    </div>
  )
}
