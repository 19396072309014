import { useState } from "react"
import PatientAuthHeadline from "../components/PatientAuthHeadline"
import PatientAuthWrapper from "../components/PatientAuthWrapper"
import { CloudUpload, X } from "lucide-react"
import { Input } from "@/components/Input"
import { Button } from "@/components/Button"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useForm } from "react-hook-form"
import { useToast } from "@/hooks/useToast"
import axios from "axios"
import ErrorMessage from "@/components/ErrorMessage"
import LoadingPage from "@/Routes/LoadingPage"
import ErrorBlock from "@/components/ErrorBlock"
import { Checkbox } from "@/components/Checkbox"
import { CircleCheckBig } from "lucide-react"
import { useNavigate } from "react-router-dom"
import AccordionMenu from "@/components/AccordionMenu"
import Loader from "@/components/Loader"

const allowedFileTypes = [
  "application/pdf",
  "image/jpg",
  "image/jpeg",
  "image/png",
]

export const getFinancialStatementsQueryKey = "getFinancialStatements"
export default function PatientFinancialStatements() {
  const { toast } = useToast()
  const navigate = useNavigate()

  const query = useQuery({
    queryKey: [getFinancialStatementsQueryKey],
    queryFn: async () => {
      const response = await axios.get(
        import.meta.env.VITE_API_BASE_URL + "/patients/financial-statements"
      )

      return response.data
    },
  })

  const [isCreditCardHolder, setIsCreditCardHolder] = useState(false)

  const mutation = useMutation({
    mutationFn: async () => {
      await axios.post(
        import.meta.env.VITE_API_BASE_URL + "/patients/update-card-type",
        {
          cardType: isCreditCardHolder ? "CREDIT_CARD" : "NOT_SET",
        }
      )
    },
    onSuccess: () => {
      navigate("/patients/guarantor-information")
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Something went wrong. Please try again later.",
        variant: "destructive",
      })
    },
  })

  if (query.isLoading) {
    return <LoadingPage />
  }

  if (query.isError) {
    return <ErrorBlock />
  }

  const { mobileMoneyStatements, bankAccountStatements } = query.data

  const cannotProceed =
    mobileMoneyStatements.length === 0 && bankAccountStatements.length === 0

  return (
    <PatientAuthWrapper>
      <PatientAuthHeadline text="Financial Statements" />
      <p>Upload your past 12 Month Bank and Mpesa Statements</p>

      <StatementUploadForm
        title="MPESA Statement"
        description="Upload your past 12 Month Bank Statement"
        statementType="MOBILE_MONEY"
        files={mobileMoneyStatements}
      />
      <StatementUploadForm
        title="Bank Statement"
        description="Upload your past 12 Month Bank Statement"
        statementType="BANK_ACCOUNT"
        files={bankAccountStatements}
      />

      <div className="flex items-center space-x-2">
        <Checkbox
          id="terms"
          checked={isCreditCardHolder}
          onCheckedChange={() => setIsCreditCardHolder(!isCreditCardHolder)}
        />
        <label
          htmlFor="terms"
          className="text-sm  leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 flex gap-2 items-center"
        >
          Tick the box if you are a credit card holder
          <CircleCheckBig className="w-4 h-4 text-green-500" />
        </label>
      </div>
      <Button
        className="w-full "
        disabled={cannotProceed || mutation.isPending}
        isLoading={mutation.isPending}
        onClick={async (e) => {
          e.preventDefault()
          await mutation.mutate()
        }}
      >
        Next
      </Button>
    </PatientAuthWrapper>
  )
}

type FinancialStatementType = "BANK_ACCOUNT" | "MOBILE_MONEY"

type UploadedFile = {
  id: number
  fileName: string
}

type Inputs = {
  file: FileList
}
function StatementUploadForm({
  title,
  description,
  statementType,
  files,
}: {
  title: string
  description: string
  statementType: FinancialStatementType
  files: UploadedFile[]
}) {
  const { toast } = useToast()
  const [active, setActive] = useState(false)

  const queryClient = useQueryClient()

  const {
    register,
    reset,
    getValues,
    formState: { errors },
  } = useForm<Inputs>()

  const mutation = useMutation({
    mutationFn: async (data: Inputs) => {
      const formData = new FormData()
      formData.append("financialStatementFile", data.file[0])
      formData.append("statementType", statementType)

      await axios.post(
        import.meta.env.VITE_API_BASE_URL +
          "/patients/upload-financial-statement",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
    },
    onSuccess: () => {
      reset()
      toast({
        title: "Success",
        description: "File uploaded successfully",
      })
      queryClient.invalidateQueries({
        queryKey: [getFinancialStatementsQueryKey],
      })
    },
    onError: (error: any) => {
      reset()
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        variant: "destructive",
      })
    },
  })

  const deleteFileMutation = useMutation({
    mutationFn: async (id: number) => {
      const result = await axios.delete(
        import.meta.env.VITE_API_BASE_URL +
          `/patients/delete-financial-statement/${id}`
      )

      return result.data
    },
    onSuccess: (result) => {
      toast({
        title: "Success",
        description: result.message,
      })
      queryClient.invalidateQueries({
        queryKey: [getFinancialStatementsQueryKey],
      })
      reset()
    },
    onError: (error: any) => {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        variant: "destructive",
      })
    },
  })

  return (
    <AccordionMenu
      title={title}
      description={description}
      onClick={() => setActive(!active)}
      buttonLabel="show upload form"
      icon={<CloudUpload className="w-7 h-7 text-gray-500" />}
      active={active}
      isRequired={true}
      buttonDisabled={mutation.isPending}
    >
      <div className="text-gray-500 text-xs mt-3 -mb-3 font-medium flex justify-between items-center">
        Files uploaded ({files?.length})
        {deleteFileMutation.isPending && <Loader className="w-4 h-4" />}
      </div>

      {active && (
        <>
          {files.length > 0 && (
            <div className="mt-5 py-3 border-y ">
              <div className="max-h-[200px] overflow-y-scroll flex flex-col gap-2">
                {files.map((file: any) => (
                  <div
                    key={file.id}
                    className="flex gap-2 items-center bg-gray-50 px-3 py-2 text-xs border border-dashed rounded-md"
                  >
                    {file.fileName}

                    <button
                      aria-label="Remove guarantor"
                      className="ml-auto"
                      disabled={
                        mutation.isPending || deleteFileMutation.isPending
                      }
                      onClick={() => {
                        deleteFileMutation.mutate(file.id)
                      }}
                    >
                      <X className="w-4 h-4 text-gray-500" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          <form className="mt-5  text-gray-500 flex flex-col ">
            <div className={`${mutation.isPending && "hidden"}`}>
              <p className="text-xs text-center pb-1">
                Browse to select a document, then click to upload to save
                it.Supported file types : JPEG,PNG, PDF, Max file size :2mb
              </p>
              <Input
                type="file"
                className={`w-full my-3 `}
                {...register("file", {
                  onChange: async () => {
                    mutation.mutate(getValues())
                  },
                  disabled: mutation.isPending || deleteFileMutation.isPending,
                  required: {
                    value: true,
                    message: "Please select a file to upload",
                  },
                  validate: (files: FileList) => {
                    if (files[0].size > 1024 * 1024 * 5) {
                      return "File size must be less than 5MB"
                    }

                    if (!allowedFileTypes.includes(files[0].type)) {
                      return "File type must be PDF, JPG, or PNG"
                    }
                    return true
                  },
                })}
              />
            </div>

            {mutation.isPending && (
              <div className="grid place-content-center py-2">
                <Loader />
              </div>
            )}

            {errors.file?.message && (
              <ErrorMessage message={errors.file.message} />
            )}
          </form>
        </>
      )}
    </AccordionMenu>
  )
}
