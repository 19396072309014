import { Route, Routes } from "react-router-dom"
import BillingSettings from "../components/settings/BillingSettings"
import UserManagementSettings from "../components/settings/UserManagementSettings"
import MyProfileSettings from "../components/settings/MyProfileSettings"
import HealthcareProviderSettings from "../components/settings/CareProviderProfileSettings"
import RouteMetadata from "@/components/RouteMetadata"

export default function HealthcareSettings() {
  return (
    <div className="w-full">
      <Routes>
        <Route path="/" element={<div>Settings</div>} />
        <Route
          path="/billing"
          element={
            <RouteMetadata title="Billing">
              <BillingSettings />
            </RouteMetadata>
          }
        />
        <Route
          path="/manage-users"
          element={
            <RouteMetadata title="Manage Users">
              <UserManagementSettings />
            </RouteMetadata>
          }
        />
        <Route
          path="/my-profile"
          element={
            <RouteMetadata title="Profile">
              <MyProfileSettings />
            </RouteMetadata>
          }
        />
        <Route
          path="/care-provider-profile"
          element={
            <RouteMetadata title="Care Provider">
              <HealthcareProviderSettings />
            </RouteMetadata>
          }
        />
      </Routes>
    </div>
  )
}
