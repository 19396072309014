import { Button } from "@/components/Button"
import { Link, useNavigate } from "react-router-dom"
import PatientPageWrapper from "./PatientPageWrapper"
import { usePatientAuthStore } from "../stores/patientAuthStore"
import PatientAuthWrapper from "../components/PatientAuthWrapper"
import PatientAuthHeadline from "../components/PatientAuthHeadline"
export default function PatientDashboard() {
  const signOut = usePatientAuthStore((state: any) => state.signOut)
  const navigate = useNavigate()
  const user = usePatientAuthStore((state: any) => state.user)

  if (user.onboardingRedirectLink) {
    return (
      <PatientAuthWrapper>
        <PatientAuthHeadline text="Complete Sign Up" />

        <p>
          It looks like you haven't completed your sign up yet. Please complete
          your sign up to continue.
        </p>

        <Button
          role="link"
          onClick={() => navigate(user.onboardingRedirectLink)}
        >
          Complete Sign Up
        </Button>
      </PatientAuthWrapper>
    )
  }

  return (
    <PatientPageWrapper title="Dashboard" isRoot={true}>
      <Link to="request-medical-info" className="w-full">
        <Button className="w-full">Request Medical Info</Button>
      </Link>
      <Link to="test-payment" className="w-full">
        <Button className="w-full">Test Payment</Button>
      </Link>
      <Button
        className="w-full"
        variant="outline"
        onClick={async () => {
          await signOut()
          navigate("/patients/auth")
        }}
      >
        Sign Out
      </Button>
    </PatientPageWrapper>
  )
}
