import Description from "../typography/Description"
import { Plus } from "lucide-react"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from "@/components/Dialog"
import DialogTriggerButton from "@/components/DialogTriggerButton"
import { DialogHeader } from "@/components/Dialog"
import { useState } from "react"
import { Button } from "@/components/Button"
import { Controller, useForm } from "react-hook-form"
import FormGroupInput from "@/components/form/FormGroupInput"
import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import { toast } from "@/hooks/useToast"
import FormGroupSelect from "@/components/form/FormGroupSelect"
import BillingDetailsTable from "./BillingDetailsTable"
import HealthcarePageContainer from "../HealthcarePageContainer"
import Subtitle from "../typography/SubTitle"

type BillingMethodDialog = "SELECT" | "BANK" | "MOBILEMONEY"
const queryKey = "billing-details"

export default function BillingSettings() {
  const [showDialog, setShowDialog] = useState(false)
  const [billingMethodDialog, setBillingMethodDialog] =
    useState<BillingMethodDialog>("SELECT")

  const queryClient = useQueryClient()

  function resolveBillingMethod() {
    switch (billingMethodDialog) {
      case "SELECT":
        return (
          <SelectBillingMethodDialog
            setShowDialog={setShowDialog}
            selectBillingMethodDialog={setBillingMethodDialog}
          />
        )
      case "BANK":
        return (
          <BankAccountDialog
            queryClient={queryClient}
            setShowDialog={setShowDialog}
          />
        )
      case "MOBILEMONEY":
        return (
          <MobileMoneyDialog
            queryClient={queryClient}
            setShowDialog={setShowDialog}
          />
        )
    }
  }

  return (
    <HealthcarePageContainer
      title="Payment & Billing Settings"
      description="Set up payment and bank accounts to receive payments"
    >
      <Dialog
        open={showDialog}
        onOpenChange={() => {
          setShowDialog((state) => !state)
          setBillingMethodDialog("SELECT")
        }}
      >
        <div className="flex gap-2  items-center justify-between pb-5 border-b">
          <div className="flex flex-col gap-2 ">
            <Subtitle>Linked Accounts</Subtitle>
            <Description>Accounts linked to your care provider</Description>
          </div>

          <DialogTriggerButton onClick={() => {}} disabled={false}>
            <Plus className="w-5 h-5" />
            Add Payment Method
          </DialogTriggerButton>
        </div>

        <DialogContent className="sm:max-w-[800px]">
          {resolveBillingMethod()}
        </DialogContent>
      </Dialog>

      <BillingDetailsTable queryKey={queryKey} />
    </HealthcarePageContainer>
  )
}

function SelectBillingMethodDialog({
  selectBillingMethodDialog,
}: {
  setShowDialog: (state: boolean) => void
  selectBillingMethodDialog: (method: BillingMethodDialog) => void
}) {
  return (
    <>
      <DialogHeader>
        <DialogTitle>Select Billing method</DialogTitle>
        <DialogDescription>Add your billing details</DialogDescription>
      </DialogHeader>

      <div className="flex flex-col gap-3 py-7">
        <button
          className="flex flex-col w-full  border rounded-md p-5"
          onClick={() => selectBillingMethodDialog("BANK")}
        >
          <div className="text-lg font-medium">Business Bank Account</div>
          <Description>
            Deposit into an account owned by a care-provider or organization
          </Description>
        </button>
        <button
          className="flex flex-col w-full  border rounded-md p-5"
          onClick={() => selectBillingMethodDialog("MOBILEMONEY")}
        >
          <div className="text-lg font-medium">Mobile Money Account</div>
          <Description>
            Deposit into an Mobile Money account owned by a care-provider or
            organization
          </Description>
        </button>
      </div>
    </>
  )
}

type BankInputs = {
  bankName: string
  bankAccountName: string
  bankAccountNumber: string
  bankBranchName: string
  bankBranchCode: string
  bankSwiftCode: string
  bankCurrency: string
}

function BankAccountDialog({
  setShowDialog,
  queryClient,
}: {
  setShowDialog: (state: boolean) => void
  queryClient: QueryClient
}) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<BankInputs>()

  const mutation = useMutation({
    mutationFn: async (data: BankInputs) => {
      const result = await axios.post(
        import.meta.env.VITE_API_BASE_URL + "/healthcare/create-bank-account",
        data
      )

      return result.data
    },
    onSuccess: (result) => {
      toast({
        title: "Success",
        description: result.message,
      })
      setShowDialog(false)
      queryClient.invalidateQueries({
        queryKey: [queryKey],
      })
    },
    onError: (error: any) => {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        variant: "destructive",
      })
    },
  })

  return (
    <>
      <DialogHeader>
        <DialogTitle>Bank Details</DialogTitle>
        <DialogDescription>Enter your bank details</DialogDescription>

        <form className="grid sm:grid-cols-2 gap-5 py-7">
          <FormGroupInput
            id="bankName"
            label="Bank Name"
            type="text"
            placeholder="Enter your bank name"
            register={register("bankName", {
              required: {
                value: true,
                message: "Please enter your bank name",
              },
            })}
            error={errors.bankName?.message}
          />
          <FormGroupInput
            id="bankAccountName"
            label="Account Name"
            type="text"
            placeholder="Enter your account name"
            register={register("bankAccountName", {
              required: {
                value: true,
                message: "Please enter your account name",
              },
            })}
            error={errors.bankAccountName?.message}
          />
          <FormGroupInput
            id="bankAccountNumber"
            label="Account Number"
            type="text"
            placeholder="Enter your account number"
            register={register("bankAccountNumber", {
              required: {
                value: true,
                message: "Please enter your account number",
              },
            })}
            error={errors.bankAccountNumber?.message}
          />
          <FormGroupInput
            id="bankBranchName"
            label="Branch Name"
            type="text"
            placeholder="Enter your branch name"
            register={register("bankBranchName", {
              required: {
                value: true,
                message: "Please enter your branch name",
              },
            })}
            error={errors.bankBranchName?.message}
          />
          <FormGroupInput
            id="bankBranchCode"
            label="Branch Code"
            type="text"
            placeholder="Enter your branch code"
            register={register("bankBranchCode", {
              required: {
                value: true,
                message: "Please enter your branch code",
              },
            })}
            error={errors.bankBranchCode?.message}
          />
          <FormGroupInput
            id="bankSwiftCode"
            label="Swift Code"
            type="text"
            placeholder="Enter your swift code"
            register={register("bankSwiftCode", {
              required: {
                value: true,
                message: "Please enter your swift code",
              },
            })}
            error={errors.bankSwiftCode?.message}
          />

          {/* TODO: make this dynamic by pulling valid currencies from the backend */}
          <Controller
            name="bankCurrency"
            control={control}
            render={({ field }) => (
              <FormGroupSelect
                id="bankCurrency"
                label="Currency"
                placeholder="Select Currency"
                field={field}
                error={errors.bankCurrency?.message}
                options={[
                  {
                    name: "KES",
                    value: "KES",
                  },
                ]}
              />
            )}
          />
        </form>
        <DialogFooter>
          <Button
            className="min-w-32"
            type="submit"
            disabled={mutation.isPending}
            isLoading={mutation.isPending}
            onClick={handleSubmit(async (data) => {
              await mutation.mutateAsync(data)
            })}
          >
            Submit
          </Button>
        </DialogFooter>
      </DialogHeader>
    </>
  )
}

type MobileMoneyType = "NOT_SET" | "PHONE_NUMBER" | "PAYBILL" | "TILL_NUMBER"

function MobileMoneyDialog({
  setShowDialog,
  queryClient,
}: {
  setShowDialog: (state: boolean) => void
  queryClient: QueryClient
}) {
  const [mobileMoneyType, setmobileMoneyType] =
    useState<MobileMoneyType>("NOT_SET")

  return (
    <>
      <DialogHeader>
        <DialogTitle>Select Mobile Money Account Type</DialogTitle>
        <DialogDescription>
          Enter mobile money account details.
        </DialogDescription>

        <div className="flex flex-col gap-3 py-7">
          {mobileMoneyType == "NOT_SET" ? (
            <SelectmobileMoneyType setmobileMoneyType={setmobileMoneyType} />
          ) : (
            <CreateMobileMoneyAccount
              queryClient={queryClient}
              mobileMoneyType={mobileMoneyType}
              setShowDialog={setShowDialog}
            />
          )}
        </div>
      </DialogHeader>
    </>
  )
}

function SelectmobileMoneyType({
  setmobileMoneyType,
}: {
  setmobileMoneyType: (type: MobileMoneyType) => void
}) {
  return (
    <>
      <button
        className="flex flex-col w-full  border rounded-md p-5"
        onClick={() => setmobileMoneyType("TILL_NUMBER")}
      >
        <div className="text-lg font-medium">Till Number</div>
        <Description>Receive Money via Mobile Money Till Number.</Description>
      </button>
      <button
        className="flex flex-col w-full  border rounded-md p-5"
        onClick={() => setmobileMoneyType("PAYBILL")}
      >
        <div className="text-lg font-medium">Paybill Number</div>
        <Description>Receive Money via Paybill number.</Description>
      </button>
      <button
        className="flex flex-col w-full  border rounded-md p-5"
        onClick={() => setmobileMoneyType("PHONE_NUMBER")}
      >
        <div className="text-lg font-medium">Phone Number</div>
        <Description>Receive Money via phone number.</Description>
      </button>
    </>
  )
}

type MobileMoneyInputs = {
  mobileMoneyProvider: string
  mobileMoneyAccountType: MobileMoneyType
  mobileMoneyAccountName: string
  mobileMoneyNationalIdNumber: string
  mobileMoneyPaybillNumber?: string
  mobileMoneyPaybillAccountNumber?: string
  mobileMoneyPhoneNumber?: string
  mobileMoneyTillNumber?: string
}

function CreateMobileMoneyAccount({
  mobileMoneyType,
  setShowDialog,
  queryClient,
}: {
  mobileMoneyType: MobileMoneyType
  setShowDialog: (state: boolean) => void
  queryClient: QueryClient
}) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<MobileMoneyInputs>()

  const mutation = useMutation({
    mutationKey: [queryKey],
    mutationFn: async (data: MobileMoneyInputs) => {
      const result = await axios.post(
        import.meta.env.VITE_API_BASE_URL +
          "/healthcare/create-mobile-money-account",
        data
      )

      return result.data
    },
    onSuccess: (result) => {
      toast({
        title: "Success",
        description: result.message,
      })
      setShowDialog(false)
      queryClient.invalidateQueries({
        queryKey: [queryKey],
      })
    },
    onError: (error: any) => {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        variant: "destructive",
      })
    },
  })

  return (
    <form>
      <div className="grid sm:grid-cols-2 gap-5">
        <input
          type="hidden"
          {...register("mobileMoneyAccountType", {
            value: mobileMoneyType,
          })}
        />
        {/* TODO: make this dynamic by pulling valid providers from the backend */}
        <Controller
          name="mobileMoneyProvider"
          control={control}
          render={({ field }) => (
            <FormGroupSelect
              id="mobileMoneyProvider"
              label="Mobile Money Provider"
              placeholder="Select Mobile Money Provider"
              field={field}
              error={errors.mobileMoneyProvider?.message}
              options={[
                {
                  name: "MPESA",
                  value: "MPESA",
                },
              ]}
            />
          )}
        />

        <FormGroupInput
          id="mobileMoneyAccountName"
          label="Account Holder's Full Name"
          type="text"
          placeholder="Enter account holder's full name"
          register={register("mobileMoneyAccountName", {
            required: {
              value: true,
              message: "Please enter account holder's full name",
            },
          })}
          error={errors.mobileMoneyAccountName?.message}
        />
        {mobileMoneyType === "TILL_NUMBER" && (
          <FormGroupInput
            id="mobileMoneyTillNumber"
            label="Mobile Money Till Number"
            type="text"
            placeholder="Enter Mobile Money Till Number"
            register={register("mobileMoneyTillNumber", {
              required: {
                value: true,
                message: "Please enter Mobile Money Till Number",
              },
            })}
            error={errors?.mobileMoneyTillNumber?.message}
          />
        )}
        {mobileMoneyType === "PAYBILL" && (
          <>
            <FormGroupInput
              id="mobileMoneyPaybillNumber"
              label="Mobile Money Paybill Number"
              type="text"
              placeholder="Enter Mobile Money Paybill Number"
              register={register("mobileMoneyPaybillNumber", {
                required: {
                  value: true,
                  message: "Please enter Mobile Money Paybill Number",
                },
              })}
              error={errors?.mobileMoneyPaybillNumber?.message}
            />
            <FormGroupInput
              id="mobileMoneyPaybillAccountNumber"
              label="Mobile Money Paybill Account Number"
              type="text"
              placeholder="Enter Mobile Money Paybill Account Number"
              register={register("mobileMoneyPaybillAccountNumber", {
                required: {
                  value: true,
                  message: "Please enter Mobile Money Paybill Account Number",
                },
              })}
              error={errors?.mobileMoneyPaybillAccountNumber?.message}
            />
          </>
        )}
        {mobileMoneyType === "PHONE_NUMBER" && (
          <FormGroupInput
            id="mobileMoneyPhoneNumber"
            label="Mobile Money Phone Number"
            type="text"
            placeholder="Enter Mobile Money phone number"
            register={register("mobileMoneyPhoneNumber", {
              required: {
                value: true,
                message: "Please enter Mobile Money phone number",
              },
            })}
            error={errors?.mobileMoneyPhoneNumber?.message}
          />
        )}
        <FormGroupInput
          id="mobileMoneyNationalIdNumber"
          label="National ID Number"
          type="text"
          placeholder="Enter National ID Number"
          register={register("mobileMoneyNationalIdNumber", {
            required: {
              value: true,
              message: "Please enter National ID Number",
            },
          })}
          error={errors.mobileMoneyNationalIdNumber?.message}
        />
      </div>

      <DialogFooter>
        <Button
          type="submit"
          className="min-w-32"
          isLoading={mutation.isPending}
          disabled={mutation.isPending}
          onClick={handleSubmit(async (data) => {
            await mutation.mutateAsync(data)
          })}
        >
          Submit
        </Button>
      </DialogFooter>
    </form>
  )
}
