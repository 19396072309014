import { create } from "zustand"
import Session from "supertokens-web-js/recipe/session"
import { CountryCode } from "libphonenumber-js"

export const usePatientAuthStore = create((set) => ({
  user: null,
  signUpDetails: null,
  setSignUpDetails: (
    phoneNumber: string,
    countryCode: CountryCode,
    firstName: string,
    lastName: string,
    whatsappNumber: string,
    whatsappCountryCode: CountryCode
  ) =>
    set((state: any) => {
      const newState = {
        ...state.user,
        phoneNumber,
        countryCode,
        firstName,
        lastName,
        whatsappNumber,
        whatsappCountryCode,
      }
      return {
        signUpDetails: newState,
      }
    }),
  setUserId: (userId: string) =>
    set((state: any) => {
      const newState = {
        ...state.signUpDetails,
        userId,
      }

      return {
        signUpDetails: newState,
      }
    }),
  setUser: (user: any) =>
    set(() => {
      return { user }
    }),
  signOut: () =>
    set(async () => {
      await Session.signOut()
      return { user: null }
    }),
}))
