import { CountryCode, isValidPhoneNumber } from "libphonenumber-js"

export function validatePhoneNumber({
  countryCode,
  phoneNumber,
}: {
  countryCode: CountryCode
  phoneNumber: string
}) {
  return isValidPhoneNumber(phoneNumber, countryCode)
}
