import { useNavigate } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import Session from "supertokens-web-js/recipe/session"
import ErrorPage from "./ErrorPage"
import LoadingPage from "./LoadingPage"
import SplashScreen from "./SplashScreen"

const queryKey = "getTenantId"
export default function Home() {
  const navigate = useNavigate()

  const query = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      const doesSessionExist = await Session.doesSessionExist()

      if (!doesSessionExist) {
        return false
      }
      const result = await axios.get(
        import.meta.env.VITE_API_BASE_URL + "/users/tenant-id"
      )

      if (result.data.tenantId === "patients") {
        navigate("/patients/")
        return result.data.tenantId
      }

      if (result.data.tenantId === "healthcare") {
        navigate("/healthcare/")
        return result.data.tenantId
      }

      return result.data.tenantId
    },
  })

  if (query.isError) {
    return <ErrorPage />
  }

  if (query.isLoading) {
    return <LoadingPage />
  }

  return <SplashScreen />
}
