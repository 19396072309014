import { useForm } from "react-hook-form"
import HealthcarePageContainer from "../HealthcarePageContainer"
import FormGroupInput from "@/components/form/FormGroupInput"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import LoadingPage from "@/Routes/LoadingPage"
import ErrorBlock from "@/components/ErrorBlock"
import Subtitle from "../typography/SubTitle"
import Description from "../typography/Description"
import {
  HealthcareUser,
  HealthcareWorkerRole,
  useHealthcareUserStore,
} from "../../stores/healthcareUserStore"
type Inputs = {
  firstName: string
  lastName: string
  title: string
  email: string
  phoneNumber: string
}

const queryKey = "healthcareProfileDetails"
export default function MyProfileSettings() {
  const user = useHealthcareUserStore(
    (state: any) => state.user
  ) as HealthcareUser
  const {
    register,
    formState: { errors },
  } = useForm<Inputs>()

  const query = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      const response = await axios.get(
        import.meta.env.VITE_API_BASE_URL + "/healthcare/my-profile-details"
      )

      return response.data
    },
  })

  if (query.isLoading) return <LoadingPage />
  if (query.isError) return <ErrorBlock />

  const {
    firstName,
    lastName,
    title,
    email,
    phoneNumber,
    facilityName,
    ownerIdType,
    ownerIdNumber,
  } = query.data

  return (
    <HealthcarePageContainer
      title="My Profile"
      description="Manage your personal profile"
    >
      <div className="flex flex-col gap-10 border rounded-xl max-w-[1000px]">
        <div className="w-full h-32 bg-primary/30 rounded-xl relative">
          <div
            className="rounded-full w-24 h-24  grid place-content-center text-5xl border-[6px] border-white bg-primary text-white absolute left-1/2 -bottom-8 -ml-12"
            aria-hidden="true"
          >
            {firstName[0]}
          </div>
        </div>

        <div className="text-center flex flex-col gap-2 p-5">
          <h2 className="text-2xl font-medium">
            {firstName} {lastName}
          </h2>
          <h3 className="font-medium text-gray-500">
            {title} - {facilityName}
          </h3>

          <form className="grid md:grid-cols-2 gap-7 mt-5">
            <FormGroupInput
              id="firstName"
              label="First Name"
              type="text"
              placeholder="Enter your first name"
              readonly={true}
              register={register("firstName", {
                value: firstName,
                required: {
                  value: true,
                  message: "Please enter your first name",
                },
              })}
              error={errors.firstName?.message}
            />
            <FormGroupInput
              id="lastName"
              label="Last Name"
              type="text"
              placeholder="Enter your last name"
              readonly={true}
              register={register("lastName", {
                value: lastName,
                required: {
                  value: true,
                  message: "Please enter your last name",
                },
              })}
              error={errors.lastName?.message}
            />
            <FormGroupInput
              id="title"
              label="Title"
              type="text"
              placeholder="Enter title"
              readonly={true}
              register={register("title", {
                value: title,
              })}
              error={errors.title?.message}
            />
            <FormGroupInput
              id="email"
              label="Email"
              type="email"
              placeholder="Enter your email address"
              readonly={true}
              register={register("email", {
                value: email,
                required: {
                  value: true,
                  message: "Please enter your email address",
                },
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: "Please enter a valid email address",
                },
              })}
              error={errors.email?.message}
            />
            <FormGroupInput
              id="phoneNumber"
              label="Phone Number"
              type="tel"
              placeholder="Enter your phone number"
              readonly={true}
              register={register("phoneNumber", {
                value: phoneNumber,
                required: {
                  value: true,
                  message: "Please enter your phone number",
                },
              })}
              error={errors.phoneNumber?.message}
            />
          </form>

          {user?.role === HealthcareWorkerRole.OWNER && (
            <>
              <div className="flex flex-col gap-2 mt-10 text-left">
                <Subtitle>Identity Verification</Subtitle>
                <Description>
                  Your legal identity verification, once approved, you will not
                  be able to edit this
                </Description>

                <div className="grid md:grid-cols-2 mt-5">
                  <div className="flex flex-col gap-2">
                    <label
                      className="text-sm font-medium text-gray-500"
                      htmlFor="ownerIdType"
                    >
                      Identification Type
                    </label>

                    <input
                      type="text"
                      className="w-full"
                      id="ownerIdType"
                      value={ownerIdType}
                      readOnly
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label
                      className="text-sm font-medium text-gray-500"
                      htmlFor="ownerIdNumber"
                    >
                      Identification Number
                    </label>
                    <input
                      type="text"
                      className="w-full"
                      id="ownerIdNumber"
                      value={ownerIdNumber}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </HealthcarePageContainer>
  )
}
