import HealthcareAuthTitle from "./HealthcareAuthTitle"

export default function HealthcareRequestCompleteSignUp() {
  return (
    <div>
      <HealthcareAuthTitle>Request Complete Sign Up</HealthcareAuthTitle>

      <p>Please contact your facility owner to complete your sign up.</p>
    </div>
  )
}
