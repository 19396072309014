import { useMutation, useQuery } from "@tanstack/react-query"
import axios from "axios"
import { Button } from "@/components/Button"
import { useToast } from "@/hooks/useToast"
import { Link, Route, Routes, useSearchParams } from "react-router-dom"
import LoadingPage from "@/Routes/LoadingPage"
import ErrorBlock from "@/components/ErrorBlock"
import { ShieldCheck } from "lucide-react"
import { CircleX } from "lucide-react"
import PatientPageWrapper from "../PatientPageWrapper"
import RouteMetadata from "@/components/RouteMetadata"

//TODO: for testing purposes only. Must be removed
export default function TestPaymentWrapper() {
  return (
    <PatientPageWrapper title="Test Payment">
      <Routes>
        <Route
          path="/"
          element={
            <RouteMetadata title="Test Payment">
              <TestPayment />
            </RouteMetadata>
          }
        />
        <Route
          path="/result"
          element={
            <RouteMetadata title="Test Payment Result">
              <TestPaymentResult />
            </RouteMetadata>
          }
        />
      </Routes>
    </PatientPageWrapper>
  )
}
function TestPayment() {
  const { toast } = useToast()

  const mutation = useMutation({
    mutationFn: async () => {
      const response = await axios.post(
        import.meta.env.VITE_SUPERTOKENS_API_DOMAIN + "/initialize-payment"
      )

      return response.data
    },
    onError: (error: any) => {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        variant: "destructive",
      })
    },
    onSuccess: (data) => {
      const { authorization_url } = data.data.data
      window.location.assign(authorization_url)
    },
  })
  return (
    <>
      <p>This is a test payment of 10KES </p>

      <p>
        You can find a list of test payment methods{" "}
        <a
          href="https://paystack.com/docs/payments/test-payments/"
          target="_blank"
        >
          here
        </a>
      </p>

      <Button
        className="w-full mt-5"
        onClick={async () => {
          await mutation.mutate()
        }}
        disabled={mutation.isPending}
        isLoading={mutation.isPending}
      >
        Initialize
      </Button>
    </>
  )
}

function TestPaymentResult() {
  let [params] = useSearchParams()

  const reference = params.get("reference")

  const query = useQuery({
    queryKey: ["testPaymentResult"],
    queryFn: async () => {
      const response = await axios.get(
        import.meta.env.VITE_SUPERTOKENS_API_DOMAIN +
          `/payment-status/${reference}`
      )

      return response.data
    },
  })

  if (query.isLoading) {
    return <LoadingPage />
  }

  if (query.isError) {
    return <ErrorBlock />
  }

  const { data } = query.data

  const { status } = data.data

  console.log(data)

  return (
    <div className="flex flex-col items-center gap-10 w-full">
      <h1>Payment {status === "success" ? "Success" : "Failed"}</h1>

      {status === "success" ? (
        <ShieldCheck className="w-20 h-20 text-green-500" />
      ) : (
        <CircleX className="w-20 h-20 text-red-500" />
      )}

      <Link to="/test-payment" className="w-full">
        <Button className="w-full" role="link">
          Try Another Payment
        </Button>
      </Link>
    </div>
  )
}
