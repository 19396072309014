import { Link } from "react-router-dom"

const SplashScreen = () => {
  return (
    <div className="relative h-screen bg-purple-600  rounded-sm w-full flex flex-col  sm:border sm:border-input">
      <div className="h-full flex flex-col md:flex-row">
        
        <div className="relative h-[60%] md:h-full md:w-1/2 p-4 md:p-8 lg:p-12 flex items-center justify-center bg-white ">
          <div className="relative w-full max-w-sm md:max-w-md lg:max-w-lg h-full flex items-center justify-center">
            <img src="/illustration.png" alt="Illustration" className="w-4/5 md:w-3/4 lg:w-full" />
          </div>

          <div className="absolute bottom-0 w-full md:hidden">
            <div className="relative w-full h-8">
              <div className="absolute top-0 w-full h-16 bg-white rounded-b-[20%] transform translate-y-4 z-20" />
            </div>
          </div>
        </div>

        
        <div className=" relative md:w-1/2 flex flex-col items-start md:items-start justify-center bg-purple-600 mt-20 px-6 md:px-12 lg:px-20 pt-8 md:pt-0 text-center md:text-start">
          <h1 className="text-xl md:text-2xl lg:text-3xl font-semibold text-white mb-4 md:my-8">
            Equity in healthcare
          </h1>
          <p className="text-xs md:text-sm lg:text-base text-white opacity-90 mb-8 lg:mb-12">
            We help you take control of your healthcare finances
          </p>

          <div className=" mt-8  flex flex-row justify-between lg:justify-start lg:gap-10 gap-4 w-full max-w-xs md:max-w-sm lg:max-w-md mb-4 ">
            <Link to="/">
              <button className="w-full bg-white text-purple-600 border-2 border-white py-3 px-10 md:px-8 lg:px-10 rounded-lg font-semibold">
                Sign up
              </button>
            </Link>
            <Link to="/">
              <button className="w-full border-2 border-white text-white py-3 px-10 md:px-12 lg:px-10 rounded-lg font-semibold">
                Log in
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SplashScreen
