import { Link, useNavigate } from "react-router-dom"
import { useHealthcareUserStore } from "../stores/healthcareUserStore"
import { useMutation } from "@tanstack/react-query"
import Session from "supertokens-web-js/recipe/session"
import {
  Wallet,
  Landmark,
  Users,
  CircleHelp,
  LogOut,
  UserPen,
  Hospital,
} from "lucide-react"

export default function Sidebar() {
  const user = useHealthcareUserStore((state: any) => state.user)
  const removeUser = useHealthcareUserStore((state: any) => state.removeUser)

  const navigate = useNavigate()
  const signoutMutation = useMutation({
    mutationFn: async () => {
      await Session.signOut()
      removeUser()
      navigate("/healthcare/auth")
    },
  })

  return (
    <aside className="w-64 min-h-[90vh] px-5 py-10 overflow-y-auto border-r fixed hidden md:block left-0 top-16 ">
      <ul className="flex flex-col gap-7">
        <SidebarSection>
          <div className="">
            <h1 className="text-lg font-medium">
              {user?.firstName} {user?.lastName}
            </h1>

            <h2 className="font-medium text-gray-500">{user?.title}</h2>
          </div>
        </SidebarSection>

        <SidebarSection>
          <SidebarSubsection title="Patient Management">
            <SidebarLink to="">
              <Wallet className="w-5 h-5" />
              Patient Loan Requests
            </SidebarLink>
          </SidebarSubsection>

          <SidebarSubsection title="Settings">
            <SidebarLink to="settings/my-profile">
              <UserPen className="w-5 h-5" />
              My Profile
            </SidebarLink>

            <SidebarLink to="settings/care-provider-profile">
              <Hospital className="w-5 h-5" />
              Care Provider Profile
            </SidebarLink>
            <SidebarLink to="settings/billing">
              <Landmark className="w-5 h-5" />
              Payment and Billing
            </SidebarLink>
            <SidebarLink to="settings/manage-users">
              <Users className="w-5 h-5" />
              Manage Users
            </SidebarLink>
          </SidebarSubsection>
        </SidebarSection>

        <SidebarSection>
          <SidebarSubsection title="">
            <SidebarLink to="mailto:supoprt@jireh-health.com">
              <CircleHelp className="w-5 h-5" />
              Need Help?
            </SidebarLink>
            <SidebarLink to="" onClick={() => signoutMutation.mutate()}>
              <LogOut className="w-5 h-5" />
              Log Out
            </SidebarLink>
          </SidebarSubsection>
        </SidebarSection>
      </ul>
    </aside>
  )
}

function SidebarSection({ children }: { children: React.ReactNode }) {
  return <li className="flex flex-col gap-7 border-b pb-4">{children}</li>
}

function SidebarSubsection({
  title,
  children,
}: {
  title: string
  children: React.ReactNode
}) {
  return (
    <div className="flex flex-col gap-1">
      {title && (
        <h2 className="font-medium text-gray-500 uppercase text-sm">{title}</h2>
      )}
      <ul className="flex flex-col gap-2 mt-1">{children}</ul>
    </div>
  )
}

function SidebarLink({
  to,
  onClick,
  children,
}: {
  to: string
  children: React.ReactNode
  onClick?: () => void
}) {
  return (
    <Link
      to={to}
      className="flex gap-1 items-center text-gray-700 no-underline hover:text-primary hover:underline"
      onClick={onClick}
    >
      {children}
    </Link>
  )
}
