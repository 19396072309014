import { Route, Routes } from "react-router-dom"
import PatientSignUp from "./Pages/PatientSignUp"
import PatientLogIn from "./Pages/PatientLogIn"
import PatientCompleteSignUp from "./Pages/PatientCompleteSignUp"
import PatientsHome from "./Pages/PatientsHome"
import { useEffect } from "react"
import { setToLocalStorage } from "@/utilities/localStorage"
import RouteMetadata from "@/components/RouteMetadata"

export default function PatientWrapper() {
  useEffect(() => {
    setToLocalStorage("tenantId", "patients")
  }, [])

  return (
    <main className="">
      <Routes>
        <Route path="/auth" element={<PatientSignUp />} />
        <Route
          path="/auth/login"
          element={
            <RouteMetadata title="Log In">
              <PatientLogIn />
            </RouteMetadata>
          }
        />
        <Route
          path="/auth/complete-signup"
          element={
            <RouteMetadata title="Complete Sign Up">
              <PatientCompleteSignUp />
            </RouteMetadata>
          }
        />

        <Route
          path="/*"
          element={
            <RouteMetadata title="Patient">
              <PatientsHome />
            </RouteMetadata>
          }
        />
      </Routes>
    </main>
  )
}
