import { HTMLInputTypeAttribute } from "react"
import { Input } from "../Input"
import { Label } from "../Label"
import { UseFormRegisterReturn } from "react-hook-form"
import ErrorMessage from "../ErrorMessage"
import FormGroupWrapper from "./FormGroupWrapper"

type FormGroupProps = {
  className?: string
  label: string
  id: string
  type: HTMLInputTypeAttribute
  placeholder?: string
  register: UseFormRegisterReturn
  readonly?: boolean
  error: string | undefined
  inputMode?:
    | "text"
    | "numeric"
    | "tel"
    | "url"
    | "email"
    | "decimal"
    | undefined
} & (
  | { type: "file"; multiple?: boolean }
  | {
      type: Exclude<HTMLInputTypeAttribute | "textarea", "file">
      multiple?: undefined
    }
)

export default function FormGroupInput({
  id,
  label,
  type,
  multiple,
  placeholder,
  register,
  error,
  inputMode,
  className,
  readonly,
}: FormGroupProps) {
  return (
    <FormGroupWrapper className={className}>
      <Label htmlFor={id}>{label}</Label>
      <Input
        id={id}
        type={type}
        {...(type === "file" && { multiple })}
        placeholder={placeholder}
        inputMode={inputMode}
        {...register}
        aria-invalid={error ? true : false}
        readOnly={readonly}
      />
      {error && <ErrorMessage message={error} />}
    </FormGroupWrapper>
  )
}
