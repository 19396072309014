import ErrorMessage from "../ErrorMessage"
import FormGroupWrapper from "./FormGroupWrapper"
import { Label } from "../Label"
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../Select"

type FormGroupProps = {
  label: string
  id: string
  placeholder?: string
  field: any
  error: string | undefined
  options: {
    name: string
    value: string
  }[]
}

export default function FormGroupSelect({
  id,
  label,
  placeholder,
  field,
  error,
  options,
}: FormGroupProps) {
  return (
    <FormGroupWrapper>
      <Label htmlFor={id}>{label}</Label>
      <Select onValueChange={field.onChange} name={id}>
        <SelectTrigger className="w-full" id={id}>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent className="z-max">
          <SelectGroup>
            <SelectLabel>{label}</SelectLabel>
            {options.map((option) => (
              <SelectItem key={option.name} value={option.value}>
                {option.name}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
      {error && <ErrorMessage message={error} />}
    </FormGroupWrapper>
  )
}
