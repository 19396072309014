export default function PatientAuthWrapper({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <main className="flex justify-center py-10">
      <section className=" max-w-[400px] rounded-sm w-full flex flex-col  gap-7 px-5 py-7 sm:border sm:border-input">
        {children}
      </section>
    </main>
  )
}
