import { CircleAlert } from "lucide-react"

// Defines a block of text that is shown when caught errors occur within a component
//Differentiates from ErrorPage which is used for uncaught errors
export default function ErrorBlock({ message }: { message?: string }) {
  return (
    <div className="flex flex-col gap-3 h-full text-center items-center">
      <CircleAlert className="h-16 w-16 text-red-500" />
      <h1 className="text-xl font-bold">Something went wrong</h1>
      <p>
        {message ||
          "Please try again later or contact support if the problem persists."}
      </p>
    </div>
  )
}
