import { Route, Routes, useNavigate } from "react-router-dom"
import { SessionAuth } from "supertokens-auth-react/recipe/session"
import axios from "axios"
import { useQuery } from "@tanstack/react-query"
import LoadingPage from "@/Routes/LoadingPage"
import PatientDashboard from "./PatientDashboard"
import { usePatientAuthStore } from "../stores/patientAuthStore"
import PatientRequestMedicalInfo from "./PatientRequestMedicalInfo"
import { PatientIdVerificationStatus } from "../enums/PatientIdVerificationStatus"
import ErrorBlock from "@/components/ErrorBlock"
import PatientLinkSocials from "./PatientLinkSocials"
import PatientVerifyId from "../Pages/PatientVerifyId"
import PatientEmploymentDetails from "../Pages/PatientEmploymentDetails"
import PatientFinancialStatements from "../Pages/PatientFinancialStatements"
import PatientGuarantorInformation from "../Pages/PatientGuarantorInformation"
import PatientTermsAndConditions from "./PatientTermsAndConditions"
import PatientVerifyWhatsapp from "../Pages/PatientVerifyWhatsapp"
import TestPaymentWrapper from "./Payment/TestPayment"
import RouteMetadata from "@/components/RouteMetadata"

// import { useNotificationPermission } from "@/hooks/useRequestNotificationAccess"

export const patientLoginDetailsQueryKey = "patientLoginDetails"
export default function PatientsHome() {
  const navigate = useNavigate()
  const setUser = usePatientAuthStore((state: any) => state.setUser)
  // TODO: re-enable when setting up facebook auth
  // const location = useLocation()

  // const notifcationPermission = useNotificationPermission()

  const query = useQuery({
    queryKey: [patientLoginDetailsQueryKey],
    queryFn: async () => {
      //various onboarding checks
      try {
        const res = await axios.get(
          import.meta.env.VITE_API_BASE_URL + "/patients/login-details"
        )

        let onboardingRedirectLink = ""

        function setUserDetails() {
          const userDetails = {
            ...res.data,
            onboardingRedirectLink,
          }

          setUser(userDetails)
          return userDetails
        }

        const { hasVerifiedWhatsApp } = res.data
        //Set when onboarding has not been completed

        if (!hasVerifiedWhatsApp) {
          onboardingRedirectLink = "/patients/verify-whatsapp"
          return setUserDetails()
        }

        const { isVerified } = res.data

        if (!isVerified) {
          onboardingRedirectLink = "/patients/auth/complete-signup/"
          return setUserDetails()
        }

        const { idVerificationStatus } = res.data

        if (idVerificationStatus === PatientIdVerificationStatus.NOT_SET) {
          onboardingRedirectLink = "/patients/verify-id"
          return setUserDetails()
        }

        const { employmentDetailsAreSet } = res.data

        if (!employmentDetailsAreSet) {
          onboardingRedirectLink = "/patients/employment-details"
          return setUserDetails()
        }

        const { hasUploadedFinancialStatements } = res.data

        if (!hasUploadedFinancialStatements) {
          onboardingRedirectLink = "/patients/financial-statements"
          return setUserDetails()
        }

        const { hasSentGuarantorInvites } = res.data

        if (!hasSentGuarantorInvites) {
          onboardingRedirectLink = "/patients/guarantor-information"
          return setUserDetails()
        }

        //TODO: re-enable after app has been published
        // const { hasLinkedSocialAccounts } = res.data

        // //Prevent looping to link-socials page after a redirect from facebook
        // if (!hasLinkedSocialAccounts) {
        //   if (location.pathname !== "/patients/link-socials/facebook") {
        //     onboardingRedirectLink = "/patients/link-socials"
        //     return setUserDetails()
        //   }
        // }

        const { hasAcceptedLatestTermsAndConditions } = res.data

        if (!hasAcceptedLatestTermsAndConditions) {
          onboardingRedirectLink = "/patients/terms-and-conditions"
          return setUserDetails()
        }

        onboardingRedirectLink = ""
        return setUserDetails()
      } catch (error: any) {
        if (error.response?.status === 401) {
          navigate("/patients/auth/login")
          return null
        }

        if (error.response?.status === 403) {
          navigate("/unauthorized")
          return null
        }

        throw error
      }
    },
  })

  if (query.isLoading) {
    return <LoadingPage />
  }

  if (query.isError) {
    const error: any = query.error
    return <ErrorBlock message={error.response?.data.message} />
  }

  return (
    <SessionAuth requireAuth={true}>
      <div className="px-5 sm:px-10 py-10 flex flex-col gap-7 max-w-[450px] mx-auto">
        <Routes>
          <Route
            path="/"
            element={
              <RouteMetadata title="Dashboard">
                <PatientDashboard />
              </RouteMetadata>
            }
          />
          <Route
            path="/verify-whatsapp"
            element={
              <RouteMetadata title="Verify Whatsapp">
                <PatientVerifyWhatsapp />
              </RouteMetadata>
            }
          />
          <Route
            path="/verify-id"
            element={
              <RouteMetadata title="Verify ID">
                <PatientVerifyId />
              </RouteMetadata>
            }
          />
          <Route
            path="/employment-details"
            element={
              <RouteMetadata title="Employment Details">
                <PatientEmploymentDetails />
              </RouteMetadata>
            }
          />
          <Route
            path="/financial-statements"
            element={
              <RouteMetadata title="Financial Statements">
                <PatientFinancialStatements />
              </RouteMetadata>
            }
          />
          <Route
            path="/guarantor-information"
            element={
              <RouteMetadata title="Guarantor Information">
                <PatientGuarantorInformation />
              </RouteMetadata>
            }
          />
          <Route
            path="/link-socials/*"
            element={
              <RouteMetadata title="Link Socials">
                <PatientLinkSocials />
              </RouteMetadata>
            }
          />
          <Route
            path="/terms-and-conditions"
            element={
              <RouteMetadata title="Terms and COnditions">
                <PatientTermsAndConditions />
              </RouteMetadata>
            }
          />
          {/* /TODO: for testing purposes only. Must be removed */}
          <Route
            path="/test-payment/*"
            element={
              <RouteMetadata title="Payment">
                <TestPaymentWrapper />
              </RouteMetadata>
            }
          />
          <Route
            path="/request-medical-info"
            element={
              <RouteMetadata title="Medical Information">
                <PatientRequestMedicalInfo />
              </RouteMetadata>
            }
          />
        </Routes>
      </div>
    </SessionAuth>
  )
}
