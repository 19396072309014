import { NavigateFunction } from "react-router-dom"
import HealthcareAuthTitle from "./HealthcareAuthTitle"
import { Controller, useForm } from "react-hook-form"
import { useMutation } from "@tanstack/react-query"
import axios from "axios"
import { Button } from "@/components/Button"
import FormGroupInput from "@/components/form/FormGroupInput"
import FormGroupSelect from "@/components/form/FormGroupSelect"


const options = [
  { name: "National ID", value: "NATIONAL_ID" },
  { name: "Passport", value: "PASSPORT" },
]

type Inputs = {
  healthcareIdType: "NATIONAL_ID" | "PASSPORT"
  healthcareIdNumber: string
}

export default function VerifyIdForm({
  navigate,
}: {
  navigate: NavigateFunction
}) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>()

  const mutation = useMutation({
    mutationFn: async (data: Inputs) => {
      await axios.post(
        `${
          import.meta.env.VITE_SUPERTOKENS_API_DOMAIN
        }/healthcare/create-owner-id-details`,
        {
          idType: data.healthcareIdType,
          idNumber: data.healthcareIdNumber,
        }
      )

      navigate("/healthcare/auth/signup/upload-documents")
    },
  })

  return (
    <form
      className="flex flex-col gap-7 text-center max-w-[400px] mx-auto"
      onSubmit={handleSubmit(async (data) => await mutation.mutate(data))}
    >
      <HealthcareAuthTitle>
        Verify your identity to complete registration
      </HealthcareAuthTitle>

      <Controller
        name="healthcareIdType"
        control={control}
        render={({ field }) => (
          <FormGroupSelect
            id="healthcareIdType"
            label="ID Type"
            placeholder="Select ID Type"
            field={field}
            error={errors.healthcareIdType?.message}
            options={options}
          />
        )}
      />

      <FormGroupInput
        id="healthcareIdNumber"
        label="ID Number"
        type="text"
        placeholder="Enter your ID number"
        register={register("healthcareIdNumber", {
          required: {
            value: true,
            message: "Please enter your ID number",
          },
          maxLength: {
            value: 25,
            message: "ID number must be less than 25 digits",
          },
        })}
        error={errors.healthcareIdNumber?.message}
      />

      <Button isLoading={mutation.isPending} disabled={mutation.isPending}>
        Sumbit
      </Button>
    </form>
  )
}
