import ErrorBlock from "@/components/ErrorBlock"
import LoadingPage from "@/Routes/LoadingPage"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/Table"
import { formatDate } from "@/utilities/dateUtilities"
import Subtitle from "../typography/SubTitle"

export default function BillingDetailsTable({
  queryKey,
}: {
  queryKey: string
}) {
  const query = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      const result = await axios.get(
        import.meta.env.VITE_API_BASE_URL + "/healthcare/billing-details"
      )

      return result.data
    },
  })

  if (query.isLoading) return <LoadingPage />
  if (query.isError) return <ErrorBlock />

  const { bankAccounts, mobileMoneyAccounts } = query.data

  return (
    <div className="flex flex-col gap-5">
      <Subtitle>Bank Accounts</Subtitle>
      <Table>
        <TableHeader>
          <TableRow className="bg-gray-200 ">
            <TableHead>Bank Name</TableHead>
            <TableHead>Branch</TableHead>
            <TableHead>Account Name</TableHead>
            <TableHead>Account Number</TableHead>
            <TableHead>Date Added</TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {bankAccounts.map((account: any) => (
            <TableRow key={account.id}>
              <TableCell className="font-medium">{account.bankName}</TableCell>
              <TableCell>{account.branchName}</TableCell>
              <TableCell>{account.accountName}</TableCell>
              <TableCell>{account.accountNumber}</TableCell>
              <TableCell>{formatDate(account.createdAt)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Subtitle>Mobile Money Accounts</Subtitle>
      <Table>
        <TableHeader>
          <TableRow className="bg-gray-200 ">
            <TableHead>Account Name</TableHead>
            <TableHead>Provider</TableHead>
            <TableHead>National ID</TableHead>
            <TableHead>Account Type</TableHead>
            <TableHead>Account Number</TableHead>
            <TableHead>Date Added</TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {mobileMoneyAccounts.map((account: any) => (
            <TableRow key={account.id}>
              <TableCell className="font-medium">
                {account.accountName}
              </TableCell>
              <TableCell>{account.mobileMoneyProvider}</TableCell>
              <TableCell>{account.nationalIdNumber}</TableCell>
              <TableCell>{account.accountType}</TableCell>
              <TableCell>
                {account.tillNumber ||
                  account.paybillNumber ||
                  account.phoneNumber}
              </TableCell>
              <TableCell>{formatDate(account.createdAt)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}
