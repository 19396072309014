import Title from "@/components/typography/Title"
import Description from "./typography/Description"

export default function HealthcarePageContainer({
  title,
  description,
  children,
}: {
  title: string
  description: string
  children: React.ReactNode
}) {
  return (
    <div className="w-full flex flex-col gap-10">
      <div className="flex flex-col gap-2 ">
        <Title>{title}</Title>
        <Description>{description}</Description>
      </div>{" "}
      {children}
    </div>
  )
}
