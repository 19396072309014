import { create } from "zustand"

export enum HealthcareWorkerRole {
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  DOCTOR = "DOCTOR",
  UNASSIGNED = "UNASSIGNED",
}
export type HealthcareUser = {
  facilityName: string
  firstName: string
  lastName: string
  email: string
  title: string
  role: HealthcareWorkerRole
  phoneNumber: string

  //sign up flow checks
  hasSetIdDetails: boolean
  hasUploadedLicense: boolean
  hasAcceptedCommercialAgreement: boolean
}

export const useHealthcareUserStore = create((set) => ({
  user: null,
  setUser: (user: any) =>
    set(() => {
      return { user }
    }),
  getUser: () => set((state: any) => state.user),
  removeUser: () =>
    set(async () => {
      return { user: null }
    }),
}))
